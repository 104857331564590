export const emailRules = [
  v => !!v || 'required.email'.translate(),
  v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(v).toLowerCase()) || 'invalid.email'.translate()
]

export const passRules = [
  v => !!v || 'required.password'.translate(),
  v => String(v || '').length > 6 || 'required.length'.translate({ length: 7 })
]

export const coreRules = [
  v => !!v || 'required.core'.translate()
]

export const smsRules = [
  v => !!v || 'required.code'.translate(),
  v => String(v || '').length > 5 || 'required.length'.translate({ length: 6 })
]
