<template>
  <v-text-field
    type="email"
    v-model="email"
    :rules="emailRules"
    :label="i18n(label)"
    :readonly="readonly"
    :outlined="outlined"
    :autofocus="autofocus"
    :prepend-inner-icon="icon"
    @keyup.enter="$emit('on-enter', $event)"
    autocomplete="off"
  />
</template>

<script>
import { emailRules } from '@/rules'

const lowerCase = (str) => String(str || '').trim().toLowerCase()

export default {
  props: {
    icon: { default: 'mdi-email-outline' },
    value: {},
    label: { default: 'field.email' },
    outlined: { default: false },
    readonly: { default: false },
    autofocus: { default: false }
  },

  data () {
    return {
      email: lowerCase(this.value),
      emailRules
    }
  },

  watch: {
    email () {
      const email = lowerCase(this.email)
      const changed = email !== lowerCase(this.value)
      if (changed) this.$emit('input', email)
    },

    value () {
      const value = lowerCase(this.value)
      const changed = value !== lowerCase(this.email)
      if (changed) this.email = value
    }
  }
}
</script>

<style>
  input[type="email"] {
    text-transform: lowercase;
  }
</style>
