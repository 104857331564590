<template>
  <v-layout
    v-if="show"
    wrap
    justify-space-around
  >
    <v-flex xs10>
      <v-alert
        dense
        text
        :type="type"
        :icon="icon"
        outlined
        :dismissible="dismissible"
      >
        {{ i18n(message) }}
        <br v-if="extra">
        <span v-if="extra">{{ extra }}</span>
      </v-alert>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    show: {},
    icon: {},
    type: {},
    extra: {},
    message: {},
    dismissible: { default: true }
  }
}
</script>
