<template>
  <v-card
    class="elevation-0"
    outlined
  >
    <HeaderCard>
      <b>{{ 'title.recovery'.translate() }}</b>
    </HeaderCard>

    <v-card-text style="padding-top: 0; padding-bottom: 0">
      <v-form
        ref="form"
        v-model="valid"
        autocomplete="off"
      >
        <IgnoreAutoFill />

        <v-layout
          wrap
          style="padding: 12px 0"
        >
          <v-flex xs12>
            <FieldEmail
              v-model="username"
              icon="mdi-shield-account"
              autofocus
              @on-enter="recoveryPass"
            />
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>

    <Message
      type="error"
      icon="mdi-cloud-alert"

      :show="showError"
      :message="auth.error"
    />

    <v-divider />

    <v-card-actions style="padding-top: 0.7em; padding-bottom: 0.7em;">
      <v-layout
        wrap
        justify-space-around
      >
        <v-btn
          text
          small
          color="primary"
          :to="linkLogin"
        >
          {{ 'btn.back'.translate() }}
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          :disabled="!valid"
          @click="recoveryPass"
          :loading="loading"
        >
          {{ 'btn.recovery'.translate() }}
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Message from '@/components/Message'
import HeaderCard from '@/components/HeaderCard'
import FieldEmail from '@/components/fields/FieldEmail'
import IgnoreAutoFill from './IgnoreAutoFill'

import {
  ROUTE_AUTH_LOGIN,
  ACTION_USER_RECOVERY
} from '@/constants'

export default {
  components: {
    Message,
    HeaderCard,

    FieldEmail,
    IgnoreAutoFill
  },

  data () {
    return {
      valid: true,
      loading: false,
      showError: false,

      username: ''
    }
  },

  computed: {
    linkLogin: () => ({ name: ROUTE_AUTH_LOGIN }),

    ...mapState({
      auth: ({ auth }) => ({ error: auth.error, loading: auth.loading })
    })
  },

  methods: {
    recoveryPass () {
      if (!this.valid) return
      if (this.loading) return
      this.loading = true

      this.$nextTick(() => this.$store.dispatch(ACTION_USER_RECOVERY, this.username))
    }
  },

  watch: {
    username () {
      this.showError = false
    },

    'auth.loading': function (loading) {
      if (this.loading !== loading) this.loading = loading
    },

    'auth.error': function (error) {
      this.showError = Boolean(error)
    }
  }
}
</script>
